export const config = {
    API_HOST : "https://api.iot.instaswap.in",
    // API_HOST : "http://localhost:3000",

    // API_URL : "https://api.bts.server1.emuron.com",
    license_code : 'BTSFRONTEND',
    license_name : 'BTS',
    maps_api : 'AIzaSyDF7ZnnNNGcrQzR4ytR4e6Ac2v1KhIc5cc',
    site_title: 'Battery Tracking System',
    new_API_HOST:"https://api-admin.instaswap.in/v1/admin/"
};