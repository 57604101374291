import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { BasicTableComponent } from './basic-table.component';

@NgModule({
  declarations: [
    BasicTableComponent
  ],
  imports: [
    SharedModule
  ],
  exports:[
    BasicTableComponent
  ]
})
export class BasicTableModule { }
