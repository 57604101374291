export const filters: any = {
    parent: {
        field_name : 'buyer_code',
        placeholder : 'Select OEM',
        input_link : 'firstBuyers',
        options: [],
        sort_seqno: 1,
        disabled: false,
        clearable: false
    },
    child: {
        field_name : 'dealer_id',
        placeholder : 'Select Dealer',
        input_link : 'regdDealers',
        options: [],
        sort_seqno: 2,
        disabled: false,
        clearable: false
    }
};